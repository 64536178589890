<template>
  <div>
    <v-card class="d-flex justify-end" flat tile elevation="0">
      <v-card
        class="pa-2 flex-grow-1 text--secondary caption font-weight-medium"
        tile
        elevation="0"
      >
        {{ $t('global.activities') }}
      </v-card>
      <v-card
        class="pa-2 text--secondary caption font-weight-medium text-right"
        tile
        elevation="0"
      >
        {{ $t('global.effort') }}
      </v-card>
      <v-card
        class="pa-2 text--secondary caption justify-end text-right font-weight-medium"
        tile
        elevation="0"
        style="width: 105px"
      >
        {{ $t('global.frequency') }}
      </v-card>
    </v-card>

    <v-divider/>

    <v-card
      v-for="(item, key) in activitySelections" :key="key"
      class="d-flex justify-end mb-0 body-2"
      flat
      tile
      elevation="0"
    >
      <v-card
        class="pa-2 flex-grow-1 screen-text-truncate align-self-center"
        :title="item.display_name"
        tile
        elevation="0"
      >
        {{ item.display_name }}
      </v-card>
      <v-card
        class="pa-2 align-self-center"
        tile
        elevation="0"
      >
        {{ item.effort }}
      </v-card>
      <v-card
        class="pa-2 pt-1 pr-0 align-self-center"
        tile
        elevation="0"
      >
        <Frequency :selection="item" :readonly="true"></Frequency>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import Frequency from '@/components/activities/Frequency.vue'

export const Variant = Object.freeze({ Offer: 1, JobApplication: 2 })

export default {
  name: 'ExplicitActivitiesTableSimplified',
  components: {
    Frequency
  },
  props: {
    activitySelections: {
      type: Array,
      required: true
    },
    variant: {
      type: Number,
      required: true
    },
  },
  data() {
    return {}
  }
}
</script>

<style scoped>
div.no-select > div > table > tbody > tr:hover {
  background-color: transparent !important;
}
.screen-text-truncate {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

@media print {
  .screen-text-truncate {
    white-space: normal !important;
    overflow: auto !important;
  }
}
</style>
