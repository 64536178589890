<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >

    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="info"
        elevation="0"
        small
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon v-if="employerId" primary>
          mdi-pencil-circle-outline
        </v-icon>
        <v-icon v-else primary>
          mdi-plus-circle-outline
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span v-if="employerId" class="text-h5">
          {{ this.$t("job_offers.employer.dialog_edit_title")}}
        </span>
        <span v-else class="text-h5">
          {{ this.$t("job_offers.employer.dialog_add_title")}}
        </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" on-submit="return false;">

            <v-row>
              <v-col>
                <v-text-field
                  v-model="name"
                  :label="$t('job_offers.employer.name')"
                  :rules="[rules.required]"
                  :error-messages="errors.name"
                  required
                  class="required"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  v-model="address1"
                  :label="$t('global.address.address1')"
                  required
                  :rules="[rules.required]"
                  class="required"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="address2"
                  :label="$t('global.address.address2')"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="zipCode"
                  :label="$t('global.address.zipCode')"
                  required
                  :rules="[rules.required]"
                  class="required"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="city"
                  :label="$t('global.address.city')"
                  required
                  :rules="[rules.required]"
                  class="required"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  v-model="website"
                  :label="$t('job_offers.employer.website')"
                  :rules="[rules.required]"
                  :error-messages="errors.website"
                  required
                  class="required"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-autocomplete
                  v-model="workingField"
                  :items="workingFields"
                  :label="$t('job_offers.employer.working_field')"
                  dense
                  item-text="name"
                  item-value="id"
                  return-object
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-checkbox
                  v-model="hasSocialSubvention"
                  :label="$t('job_offers.employer.hasSocialSubvention')"
                />
              </v-col>
              <v-col>
                <v-radio-group
                  v-model="employerType"
                  :column="false"
                  :value="employerType"
                  required
                  :rules="[rules.required]"
                  class="required"
                >
                  <v-radio
                    v-for="(v, k) in employerSizes"
                    :key="k"
                    :label="v[1]"
                    :value="v[0]"
                    class="pr-6"
                  ></v-radio>
                </v-radio-group>

              </v-col>
            </v-row>

          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="cancel"
        >
          {{ $t('job_offers.employer.dialog_close') }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="save"
        >
          {{ $t('job_offers.employer.dialog_save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import Employer, { EmployerData } from "@/models/Employer.model";

export default {
  name: 'EmployerDialog',
  props: {
    // Not null => Edition mode
    employerId: {
      required: false,
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,

      // Employer data
      name: null,
      website: null,
      workingField: null,
      hasSocialSubvention: false,
      employerType: 0,

      // Employer Address data
      address1: null,
      address2: null,
      zipCode: null,
      city: null,
      addressId: null,
      rules: {
        required: v => !!v,
        email: value => {
          if (!value) return true
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return (
            pattern.test(value) || this.$t('candidate.validation.errors.email')
          )
        },
      },

      // Server side validation errors
      errors: {
        name: '',
        website: '',
      },
    }
  },
  computed: {
    ...mapState('employers', ['employer', 'employerSizes']),
    ...mapState('professions', ['workingFields']),
  },
  watch: {
    employerId: {
      handler(employerId) {
        this.fillInitials(employerId)
      }
    }
  },
  mounted() {
    this.getEmployerSizes();
    this.getWorkingFields();
    this.fillInitials(this.employerId);
  },
  methods: {
    ...mapActions('employers', [
      'createEmployer',
      'getEmployers',
      'getEmployerSizes',
      'updateEmployer'
    ]),
    ...mapActions('professions', ['getWorkingFields']),
    ...mapMutations('employers', ['setEmployer', 'setRepresentatives']),
    fillInitials(employerId) {
      // Fill form with current stored employer
      if (employerId !== null && employerId === this.employer.id) {
        this.name = this.employer.name
        this.website = this.employer.website
        this.workingField = this.employer.workingField
        this.hasSocialSubvention = this.employer.hasSocialSubvention
        this.employerType = this.employer.size
        if (this.employer.address) {
          this.address1 = this.employer.address.address1
          this.address2 = this.employer.address.address2
          this.zipCode = this.employer.address.zipCode
          this.city = this.employer.address.city
          this.addressId = this.employer.address.id
        }
      }
    },
    cancel() {
      // Reset "add form" on cancel
      if (typeof this.employerId !== 'number') {
        this.name = null;
        this.address1 = null;
        this.address2 = null;
        this.zipCode = null;
        this.city = null;
        this.website = null;
        this.workingField = null;
        this.hasSocialSubvention = false;
        this.employerType = 0;
      }

      this.dialog = false
    },
    save() {
      if (this.$refs.form.validate()) {
        const data = new EmployerData({
          "id": this.employerId,
          "name": this.name,
          "website": this.website,
          "hasSocialSubvention": this.hasSocialSubvention,
          "size": this.employerType,
          "workingField": this.workingField,
          "address": {
            "address1": this.address1,
            "address2": this.address2,
            "zipCode": this.zipCode,
            "city": this.city,
            "id": this.addressId,
          }
        })

        if (typeof this.employerId === 'number') {
          // UPDATE
          this.updateEmployer(data).then(() => {
            // Just close the dialog
            this.dialog = false;
          }).catch(() => {
            // Server side validation errors not caught
          });
        } else {

          // CREATE
          this.createEmployer(data).then((response) => {
            // Refresh list of employers and select newly created
            // Set representatives to an empty list
            // And close dialog
            const newEmployer = new Employer(response.data);
            this.getEmployers().then(() => {
              this.setEmployer(newEmployer);
              this.setRepresentatives([]);
            });
            this.dialog = false;
          }).catch((responseError) => {
            // Server side validation errors
            const errors = responseError.response.data
            Object.entries(errors).forEach(([key, value]) => {
              this.errors[key] = value;
            });
          });

        }
      }
    }
  }
}
</script>
