<template>
  <v-card class="pa-8">
    <v-row>
      <ComponentTitleWithDataLoader
        :title="this.$t('job_offers.mobility.title')"
      ></ComponentTitleWithDataLoader>
    </v-row>
    <v-container v-if="!dataIsLoading">
      <v-row>
        <v-col>
          <v-checkbox
            v-model="drivingLicenseNeeded"
            :label="$t('job_offers.mobility.driving_license_needed')"
          />
        </v-col>
        <v-col>
          <v-checkbox
            v-model="vehicleNeeded"
            :label="$t('job_offers.mobility.vehicle_needed')"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import ComponentTitleWithDataLoader from '@/components/layout/ComponentTitleWithDataLoader.vue'

export default {
  components: { ComponentTitleWithDataLoader },
  props: {
    offerMobility: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      drivingLicenseNeeded: false,
      vehicleNeeded: false
    }
  },
  computed: {
    ...mapGetters('page', ['dataIsLoading']),
    updateData() {
      const { drivingLicenseNeeded, vehicleNeeded } = this
      return {
        drivingLicenseNeeded,
        vehicleNeeded
      }
    }
  },
  watch: {
    updateData: {
      handler() {
        this.$emit('input', {
          drivingLicenseNeeded: this.drivingLicenseNeeded,
          vehicleNeeded: this.vehicleNeeded
        })
      }
    },
    offerMobility: {
      handler() {
        this.drivingLicenseNeeded = this.offerMobility.drivingLicenseNeeded
        this.vehicleNeeded = this.offerMobility.vehicleNeeded
      }
    }
  },
  methods: {}
}
</script>
