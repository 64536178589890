<template>
  <v-card class="pa-8">
    <v-row>
      <ComponentTitleWithDataLoader
        :title="this.$t('job_offers.comments.title')"
      ></ComponentTitleWithDataLoader>
    </v-row>
    <v-container v-if="!dataIsLoading">
      <v-row>
        <v-col>
          <v-textarea
            v-model="comment"
            :label="$t('job_offers.comments.comment')"
            :hint="$t('job_offers.comments.comment_hint')"
            persistent-hint
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import ComponentTitleWithDataLoader from '@/components/layout/ComponentTitleWithDataLoader.vue'

export default {
  components: { ComponentTitleWithDataLoader },
  props: {
    offerComments: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      comment: ''
    }
  },
  computed: {
    ...mapGetters('page', ['dataIsLoading']),
    updateData() {
      const { comment } = this
      return {
        comment
      }
    }
  },
  watch: {
    updateData: {
      handler() {
        this.$emit('input', {
          comment: this.comment
        })
      }
    },
    offerComments: {
      handler() {
        this.comment = this.offerComments.comment
      }
    }
  },
  methods: {}
}
</script>
