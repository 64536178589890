<template>
  <div>
    <PageTitle
      icon="mdi-briefcase"
      back-button
      print-button
      :title="
        offerId
        ? `${$t('global.offer')} ${offerId} - ${offerName}`
        : $t('job_offers.title_new')
      "
    ></PageTitle>
    <v-form ref="form" on-submit="return false;" @submit="submitJobOffer">
      <v-row
        class="d-print-block"
      >
        <v-col cols="12" md="4" class="print-no-max-width">
          <JobEntityStatus
            entity-type="offer"
            :entity="jobOffer"
            :is-selection-valid="isSelectionValid"
          />

          <v-card class="px-8 mt-4 d-none d-print-block">
            <v-row>
              <div>
                <data-loader :custom-loading-trigger="dataIsLoading" class="mr-4" />
                <h3 v-if="!dataIsLoading">
                  {{ $t("job_offers.assigned_agent") }}
                </h3>
                <h3 v-else>{{ $t('global.loading-data') }}</h3>
              </div>
            </v-row>
            <v-row>
              {{ jobOffer?.assignedUserName }}
            </v-row>
            <p></p>
          </v-card>

          <JobOfferEmployer :offer-contact="offerContact" class="mt-8" @input="updateForm" />

          <v-card
            id="offer-activities-simplified"
            class="pa-8 mt-8"
          >
            <v-row>
              <div class="d-flex mb-4">
                <data-loader :custom-loading-trigger="dataIsLoading" class="mr-4" />
                <h3 v-if="!dataIsLoading">
                  {{ $t("job_offers.activities_preview") }}
                </h3>
                <h3 v-else>{{ $t('global.loading-data') }}</h3>
              </div>
            </v-row>
            <ExplicitActivitiesTableSimplified
              v-if="!dataIsLoading"
              :activity-selections="offerActivitySelections"
              :variant="Variant.Offer"
            >
              <template v-slot:frequencyColumn="{ item }">
                <Frequency
                  :selection="item"
                  readonly
                >
                </Frequency>
              </template>
              <template v-slot:levelColumn="{ item }">
                <TrainingLevel
                  :selection="item"
                  readonly
                >
                </TrainingLevel>
              </template>
            </ExplicitActivitiesTableSimplified>
          </v-card>
        </v-col>
        <v-col>
          <JobOfferDescription
            :offer-description="offerDescription"
            @input="updateForm"
          />
          <JobOfferAvailabilities
            :offer-availabilities="offerAvailabilities"
            class="mt-8"
            @input="updateForm"
          />
          <JobOfferMobility
            :offer-mobility="offerMobility"
            class="mt-8"
            @input="updateForm"
          />
          <JobOfferComments
            :offer-comments="offerComments"
            class="mt-8"
            @input="updateForm"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="!dataIsLoading"
        class="d-print-none mb-0"
      >
        <v-col>
          <div class="text-right py-8">
            <v-btn color="default" class="mr-6" @click="goToActivities">
              {{ $t('activities.edit_activities') }}
            </v-btn>
            <v-btn color="primary" type="submit" data-cy="submit_btn">
              {{ $t('global.save') }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import RouterMixin from '@/mixins/routerMixin'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import ExplicitActivitiesTableSimplified, {
  Variant
} from '@/components/activities/ExplicitActivitiesTableSimplified.vue'
import Frequency from '@/components/activities/Frequency.vue'
import TrainingLevel from '@/components/activities/TrainingLevel.vue'
import DataLoader from '@/components/common/DataLoader.vue'
import JobOfferMobility from '@/components/offers/JobOfferMobility.vue'
import PageTitle from '@/components/layout/PageTitle.vue'
import JobEntityStatus from '@/components/common/JobEntityStatus.vue'
import JobOfferAvailabilities from '../components/offers/JobOfferAvailabilities.vue'
import JobOfferComments from '../components/offers/JobOfferComments.vue'
import JobOfferDescription from '../components/offers/JobOfferDescription.vue'
import JobOfferEmployer from '../components/offers/JobOfferEmployer.vue'

export default {
  components: {
    ExplicitActivitiesTableSimplified,
    Frequency,
    TrainingLevel,
    DataLoader,
    JobEntityStatus,
    PageTitle,
    JobOfferAvailabilities,
    JobOfferComments,
    JobOfferDescription,
    JobOfferEmployer,
    JobOfferMobility
  },
  mixins: [RouterMixin],
  data() {
    return {
      Variant,
      form: {
        // offerDescription >>
        name: null,
        description: null,
        endDate: null,
        minimumSalary: null,
        contractDuration: null,
        criminalRecordExtractNeeded: 0,
        contractType: 0,
        address: null,
        // offerContact >>
        employer: null,
        employerRepresentative: null,
        // offerMobility >>
        drivingLicenseNeeded: null,
        vehicleNeeded: null,
        // offerComments >>
        comment: null
      }
    }
  },
  computed: {
    ...mapGetters('page', ['dataIsLoading']),
    ...mapState('jobOffers', ['jobOffer']),
    ...mapState('employers', ['employer']),
    offerId() {
      return this.$route.params.id
    },
    offerName() {
      return this.jobOffer ? `${this.jobOffer.name}` : '...'
    },
    offerContact() {
      if (this.jobOffer) {
        return {
          employer: this.form.employer,
          employerRepresentative: this.form.employerRepresentative
        }
      }
      return {}
    },
    offerDescription() {
      return {
        name: this.form.name,
        description: this.form.description,
        endDate: this.form.endDate,
        minimumSalary: this.form.minimumSalary,
        contractDuration: this.form.contractDuration,
        criminalRecordExtractNeeded: this.form.criminalRecordExtractNeeded,
        contractType: this.form.contractType,
        address: this.form.address
      }
    },
    offerAvailabilities() {
      return {
        availableFrom: this.form.availableFrom,
        irregularlyHours: this.form.irregularlyHours,
        remotePossible: this.form.remotePossible,
        rate: this.form.rate,
        workingPeriods: this.form.workingPeriods
      }
    },
    offerMobility() {
      return {
        drivingLicenseNeeded: this.form.drivingLicenseNeeded,
        vehicleNeeded: this.form.vehicleNeeded
      }
    },
    offerComments() {
      return {
        comment: this.form.comment
      }
    },
    isSelectionValid() {
      if (this.jobOffer?.versions) {
        const selections = this.jobOffer?.versions[0].jobofferactivityselection_set || []
        return (
          selections.length > 0 &&
          !selections.find((obj) => obj.activity.implicit_activity === null)
        )
      }
      return false
    },
    offerActivitySelections() {
      const versions = this.jobOffer?.versions
      if (versions) {
        const version = versions[0]
        if (version) {
          return version.jobofferactivityselection_set
        }
      }
      return []
    },
  },
  watch: {
    jobOffer: {
      handler(jobOffer) {
        if (jobOffer) {
          this.form = {
            ...this.form,
            ...jobOffer
          }
        }
      }
    },
    'jobOffer.employerRepresentative': {
      handler(employerRepresentative) {
        if (employerRepresentative) {
          this.form.employerRepresentative = employerRepresentative
        }
      }
    },
    employer: {
      // When state's employer is changed by a subcomponent
      handler(employer) {
        if (employer) {
          this.form.employer = employer
        }
      }
    }
  },
  mounted() {
    if (this.offerId) {
      this.setManualDataIsLoading(true)
      this.getJobOffer(this.offerId).then(() =>
        this.setManualDataIsLoading(false)
      )
    } else this.unselectJobOffer()
  },
  methods: {
    ...mapActions('jobOffers', [
      'createJobOffer',
      'getJobOffer',
      'updateJobOffer',
      'unselectJobOffer'
    ]),
    ...mapActions('notifications', ['showSuccess']),
    ...mapMutations('jobOffers', ['setJobOffer']),
    ...mapMutations('employers', ['setEmployer']),
    ...mapMutations('page', ['setManualDataIsLoading']),
    goToActivities() {
      this.routerPush(`activities`)
    },
    updateForm(data) {
      // Main handler to integrate subcomponents' forms updates
      this.form = Object.assign(this.form, data)
    },
    submitJobOffer(e) {
      e.preventDefault()
      if (this.$refs.form.validate()) {
        if (this.jobOffer !== null && typeof this.jobOffer.id === 'number') {
          this.form.id = this.offerId
          // Do UPDATE
          this.updateJobOffer(this.form)
        } else {
          // Do CREATE
          this.createJobOffer(this.form).then(created => {
            this.routerPush(`/offers/${created.id}/details`)
          })
        }
      }
      return false
    },
    triggerPrint() {
      if (typeof window !== 'undefined') {
        window.print()
      }
    }
  }
}
</script>
