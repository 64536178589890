<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
    :return-value.sync="formattedTime"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="formattedTime"
        :label="label"
        :rules="[rules.required]"
        prepend-icon="mdi-clock"
        class="required"
        readonly
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-time-picker
      v-model="time"
      :allowed-minutes="allowedStep"
      format="24hr"
      scrollable
      @input="update"
    />
  </v-menu>
</template>

<script>
export default {
  props: {
    value: { type: String, required: true },
    label: { type: String, required: true }
  },
  data() {
    return {
      menu: false,
      time: this.value,
      rules: {
        required: v => !!v
      }
    }
  },
  computed: {
    formattedTime: {
      get() {
        return this.value
      },
      set() {}
    }
  },
  watch: {},
  methods: {
    update(data) {
      this.menu = false
      this.$emit('update', data)
    },
    allowedStep: m => m % 15 === 0
  }
}
</script>
