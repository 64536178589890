<template>
  <v-card class="pa-8">
    <v-row>
      <ComponentTitleWithDataLoader
        :title="$t('job_offers.availabilities.title')"
      ></ComponentTitleWithDataLoader>
    </v-row>
    <v-container v-if="!dataIsLoading">
      <v-row>
        <v-col>
          <DatePicker
            id="availableFrom"
            v-model="availableFrom"
            min="1950-01-01"
            :label="$t('job_offers.availabilities.from')"
            @update="data => (availableFrom = data)"
          />
          <v-text-field
            v-model="rate"
            :label="$t('job_offers.availabilities.rate')"
            :rules="[rules.required]"
            class="required"
            data-cy="total_hours"
          />
        </v-col>
        <v-col>
          <v-checkbox
            v-model="irregularlyHours"
            :label="$t('global.irregularly_hours')"
          />
          <v-checkbox
            v-model="remotePossible"
            :label="$t('global.remote_possible')"
          />
        </v-col>
      </v-row>

      <v-divider class="py-6"></v-divider>
      <v-row
        v-for="(period, index) in workingPeriods"
        :key="period.id"
        no-gutters
      >
        <v-col cols="3">
          <v-select
            v-model="period.weekday"
            :items="weekdays"
            item-text="name"
            item-value="code"
            :label="$t('job_offers.availabilities.hours_weekday')"
            :rules="[rules.required]"
            class="required"
          />
        </v-col>
        <v-col cols="4">
          <TimePicker
            v-model="period.from_hour"
            :label="$t('job_offers.availabilities.hours_from')"
            @update="data => updatePeriodFrom(period.from_hour, data, index)"
          />
        </v-col>
        <v-col cols="4">
          <TimePicker
            v-model="period.to_hour"
            :label="$t('job_offers.availabilities.hours_to')"
            @update="data => updatePeriodTo(period.to_hour, data, index)"
          />
        </v-col>
        <v-col cols="1">
          <v-btn icon @click="removeWorkingPeriod(index)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row justify="end" class="d-print-none">
        <v-col cols="auto">
          <v-btn secondary class="text--darken-2" @click="addWorkingPeriod">
            {{ this.$t('job_offers.availabilities.hours_add') }}
            <v-icon> mdi-plus </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import DatePicker from '@/components/fields/DatePicker.vue'
import TimePicker from '@/components/fields/TimePicker.vue'
import { weekdays } from '@/helpers/weekdays'
import ComponentTitleWithDataLoader from '@/components/layout/ComponentTitleWithDataLoader.vue'

export default {
  components: { ComponentTitleWithDataLoader, TimePicker, DatePicker },
  props: {
    offerAvailabilities: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      availableFrom: null,
      irregularlyHours: false,
      remotePossible: false,
      rate: null,
      workingPeriods: [],
      rules: {
        required: v => !!v
      }
    }
  },
  computed: {
    ...mapGetters('page', ['dataIsLoading']),
    updateData() {
      const {
        availableFrom,
        irregularlyHours,
        remotePossible,
        rate,
        workingPeriods
      } = this
      return {
        availableFrom,
        irregularlyHours,
        remotePossible,
        rate,
        workingPeriods
      }
    },
    weekdays() {
      return weekdays
    },
  },
  watch: {
    updateData: {
      handler() {
        this.$emit('input', {
          availableFrom: this.availableFrom,
          irregularlyHours: this.irregularlyHours,
          remotePossible: this.remotePossible,
          rate: this.rate,
          workingPeriods: this.workingPeriods
        })
      }
    },
    offerAvailabilities: {
      handler() {
        this.availableFrom = this.offerAvailabilities.availableFrom
        this.irregularlyHours = this.offerAvailabilities.irregularlyHours
        this.remotePossible = this.offerAvailabilities.remotePossible
        this.rate = this.offerAvailabilities.rate
        this.workingPeriods = this.offerAvailabilities.workingPeriods
      }
    }
  },
  methods: {
    addWorkingPeriod() {
      if (!this.workingPeriods) {
        this.workingPeriods = []
      }
      this.workingPeriods = [
        ...this.workingPeriods,
        {
          id: null,
          from_hour: "",
          to_hour: "",
          weekday: null
        }
      ]
    },
    removeWorkingPeriod(index) {
      const newPeriods = [...this.workingPeriods]
      newPeriods.splice(index, 1)
      this.workingPeriods = newPeriods
    },
    updatePeriodFrom(period, data, index) {
      const newPeriods = [...this.workingPeriods]
      const newPeriod = { ...newPeriods[index] }
      newPeriod.from_hour = data
      newPeriods[index] = newPeriod
      this.workingPeriods = newPeriods
    },
    updatePeriodTo(period, data, index) {
      const newPeriods = [...this.workingPeriods]
      const newPeriod = { ...newPeriods[index] }
      newPeriod.to_hour = data
      newPeriods[index] = newPeriod
      this.workingPeriods = newPeriods
    }
  }
}
</script>
