<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >

    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="info"
        elevation="0"
        small
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon v-if="employerRepresentativeId" primary>
          mdi-pencil-circle-outline
        </v-icon>
        <v-icon v-else primary>
          mdi-plus-circle-outline
        </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span v-if="employerRepresentativeId" class="text-h5">
          {{ this.$t("job_offers.representative.dialog_edit_title") }}
        </span>
        <span v-else class="text-h5">
          {{ this.$t("job_offers.representative.dialog_add_title") }}
        </span>

      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" on-submit="return false;">
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="6"
              >
                <v-text-field
                  v-model="firstName"
                  :label="$t('job_offers.representative.firstname')"
                  required
                  :rules="[rules.required]"
                  class="required"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
              >
                <v-text-field
                  v-model="lastName"
                  :label="$t('job_offers.representative.lastname')"
                  required
                  :rules="[rules.required]"
                  class="required"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="address1"
                  :label="$t('global.address.address1')"
                  required
                  :rules="[rules.required]"
                  class="required"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="address2"
                  :label="$t('global.address.address2')"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="zipCode"
                  :label="$t('global.address.zipCode')"
                  required
                  :rules="[rules.required]"
                  class="required"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="city"
                  :label="$t('global.address.city')"
                  required
                  :rules="[rules.required]"
                  class="required"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="phone1"
                  :label="$t('job_offers.place.phone_1')"
                  :hint="this.$t('global.validation.hints.phone')"
                  :placeholder="this.$t('global.placeholders.phone')"
                  :rules="[rules.phone]"
                  validate-on-blur
                  required
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="phone2"
                  :label="$t('job_offers.place.phone_2')"
                  :hint="this.$t('global.validation.hints.phone')"
                  :placeholder="this.$t('global.placeholders.phone')"
                  :rules="[rules.phone]"
                  validate-on-blur
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="email"
                  :label="$t('job_offers.place.email')"
                  required
                  :rules="[rules.required, rules.email]"
                  class="required"
                ></v-text-field>
              </v-col>
            </v-row>

          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="cancel"
        >
          {{ $t('job_offers.representative.dialog_close') }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="save"
        >
          {{ $t('job_offers.representative.dialog_save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {RepresentativeData} from "@/models/Representative.model";

export default {
  name: 'RepresentativeDialog',
  props: {
    // Not null => Edition mode
    employerRepresentativeId: {
      required: false,
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,

      // Representative
      firstName: null,
      lastName: null,
      phone1: null,
      phone2: null,
      email: null,

      // Representative address
      address1: null,
      address2: null,
      zipCode: null,
      city: null,
      addressId: null,
      rules: {
        required: v => !!v,
        phone: value => {
          if (!value) return true
          const pattern = /^\+?\d*$/
          return (
            (value && value.length === 12 && pattern.test(value))
            || this.$t('global.validation.errors.phone')
          )
        },
        email: value => {
          if (!value) return true
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return (
            pattern.test(value) || this.$t('global.validation.errors.email')
          )
        },
      },
    }
  },
  computed: {
    ...mapState('employers', ['employer']),
    ...mapState('jobOffers', ['jobOffer'])
  },
  watch: {
    employerRepresentativeId: {
      handler(employerRepresentativeId) {
        this.fillInitials(employerRepresentativeId)
      }
    }
  },
  mounted() {
    this.fillInitials(this.employerRepresentativeId);
  },
  methods: {
    ...mapActions('employers', ['createRepresentative', 'updateRepresentative']),
    fillInitials(employerRepresentativeId) {
      // Fill form with current stored employer
      if (
        employerRepresentativeId !== null
        && employerRepresentativeId === this.jobOffer.employerRepresentative.id
      ) {
        this.firstName = this.jobOffer.employerRepresentative.firstName
        this.lastName = this.jobOffer.employerRepresentative.lastName
        this.phone1 = this.jobOffer.employerRepresentative.phone1
        this.phone2 = this.jobOffer.employerRepresentative.phone2
        this.email = this.jobOffer.employerRepresentative.email

        if (this.jobOffer.employerRepresentative.address) {
          this.address1 = this.jobOffer.employerRepresentative.address.address1
          this.address2 = this.jobOffer.employerRepresentative.address.address2
          this.zipCode = this.jobOffer.employerRepresentative.address.zipCode
          this.city = this.jobOffer.employerRepresentative.address.city
          this.addressId = this.jobOffer.employerRepresentative.address.id
        }
      } else if (employerRepresentativeId === null) {
        this.address1 = this.employer.address.address1
        this.address2 = this.employer.address.address2
        this.zipCode = this.employer.address.zipCode
        this.city = this.employer.address.city
      }
    },
    cancel() {
      if (typeof this.employerRepresentativeId !== 'number') {
        this.firstName = null;
        this.lastName = null;
        this.phone1 = null;
        this.phone2 = null;
        this.email = null;
        this.address1 = null;
        this.address2 = null;
        this.zipCode = null;
        this.city = null;
      }

      this.dialog = false
    },
    save() {
      if (this.$refs.form.validate()) {
        // FIXME: Use object and map in services
        const data = new RepresentativeData({
          "employerId": this.employer.id,
          "id": this.employerRepresentativeId,
          "firstName": this.firstName,
          "lastName": this.lastName,
          "phone1": this.phone1,
          "phone2": this.phone2,
          "email": this.email,
          "address": {
            "address1": this.address1,
            "address2": this.address2,
            "zipCode": this.zipCode,
            "city": this.city,
            "id": this.addressId,
          }
        })
        if (typeof this.employerRepresentativeId === 'number') {
          // UPDATE
          this.updateRepresentative(data).then(() => {
            this.dialog = false;
          }).catch(() => {
            // Server side validation errors not caught
          });
        } else {
          // CREATE
          this.createRepresentative(data).then(() => {
            this.dialog = false;
          }).catch(() => {
            // Server side validation errors not caught
          });
        }
      }
    }
  }
}
</script>
