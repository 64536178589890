<template>
  <v-card class="pa-8">
    <v-row>
      <ComponentTitleWithDataLoader
        :title="this.$t('job_offers.place.title')"
      ></ComponentTitleWithDataLoader>
    </v-row>
    <v-container v-show="!dataIsLoading" class="mt-4">
      <v-row style="flex-wrap: nowrap">
        <v-col style="min-width: 100px; max-width: 100%" class="d-flex pr-0">
          <v-autocomplete
            v-model="employer"
            :items="employerItems"
            :label="$t('job_offers.place.select_employer')"
            dense
            item-text="name"
            item-value="id"
            return-object
            :rules="[rules.required]"
            class="required mr-auto"
            data-cy="employer_select"
            @change="changeEmployer"
          ></v-autocomplete>
          <div class="flex-shrink-0 justify-end pr-0 pl-2 d-print-none">
            <EmployerDialog
              v-if="employer"
              :employer-id="employer.id"
            />
            <EmployerDialog />
          </div>
        </v-col>
      </v-row>

      <v-row v-if="employer" style="flex-wrap: nowrap">
        <v-col style="min-width: 100px; max-width: 100%" class="d-flex pr-0">
          <v-autocomplete
            v-model="employerRepresentative"
            :items="representativeItems"
            :label="$t('job_offers.place.select_representative')"
            :rules="[rules.required]"
            dense
            item-text="fullName"
            item-value="id"
            return-object
            class="required mr-auto"
            data-cy="representative_select"
            @change="changeRepresentative"
          ></v-autocomplete>
          <div class="flex-shrink-0 justify-end pr-0 pl-2 d-print-none">
            <RepresentativeDialog
              v-if="employerRepresentative !== null"
              :employer-representative-id="employerRepresentative.id"
            />
            <RepresentativeDialog />
          </div>
        </v-col>
      </v-row>
      <v-divider class="py-6"></v-divider>
      <v-row>
        <v-col>
          <v-text-field
            v-model="representativeContact.address1"
            dense
            disabled
            :label="$t('global.address.address1')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="representativeContact.address2"
            dense
            disabled
            :label="$t('global.address.address2')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="representativeContact.zipCode"
            dense
            disabled
            :label="$t('global.address.zipCode')"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="representativeContact.city"
            dense
            disabled
            :label="$t('global.address.city')"
          />
        </v-col>
      </v-row>
      <v-divider class="py-6"></v-divider>
      <v-row>
        <v-col>
          <v-text-field
            v-model="representativeContact.phone_1"
            dense
            disabled
            :label="$t('job_offers.place.phone_1')"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="representativeContact.phone_2"
            dense
            disabled
            :label="$t('job_offers.place.phone_2')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="representativeContact.email"
            dense
            disabled
            :label="$t('job_offers.place.email')"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import EmployerDialog from '@/components/offers/EmployerDialog.vue'
import RepresentativeDialog from '@/components/offers/RepresentativeDialog.vue'
import ComponentTitleWithDataLoader from '@/components/layout/ComponentTitleWithDataLoader.vue'

export default {
  components: {
    ComponentTitleWithDataLoader,
    EmployerDialog,
    RepresentativeDialog
  },
  props: {
    offerContact: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      employer: null,
      employerRepresentative: null,
      rules: {
        required: v => !!v
      },
      employers: []
    }
  },
  computed: {
    ...mapState('employers', ['representatives']),
    ...mapGetters('page', ['dataIsLoading']),
    employerItems() {
      return Object.values(this.employers ? this.employers : [])
    },
    representativeItems() {
      return Object.values(this.representatives)
    },
    representativeContact() {
      // Return a contact data object of the current selected employer representative.
      if (this.employerRepresentative) {
        const address =
          this.employerRepresentative.address === null
            ? {}
            : this.employerRepresentative.address

        return {
          ...address,
          phone_1: this.employerRepresentative.phone1,
          phone_2: this.employerRepresentative.phone2,
          email: this.employerRepresentative.email
        }
      }
      return {
        address1: '',
        address2: '',
        city: '',
        zipCode: '',
        phone_1: '',
        phone_2: '',
        email: ''
      }
    },
    updateData() {
      const { employer, employerRepresentative } = this
      return {
        employer,
        employerRepresentative
      }
    }
  },
  watch: {
    updateData: {
      handler() {
        this.$emit('input', {
          employer: this.employer,
          employerRepresentative: this.employerRepresentative
        })
      }
    },
    offerContact: {
      handler() {
        this.employer = this.offerContact.employer
        this.employerRepresentative = this.offerContact.employerRepresentative
      }
    }
  },
  mounted() {
    this.getEmployers({"page":"-1"}).then(res => {
      this.employers = res.items
    })
  },
  methods: {
    ...mapActions('employers', ['getRepresentatives', 'getEmployers']),
    ...mapMutations('employers', ['setEmployer']),
    ...mapMutations('jobOffers', ['setRepresentative']),
    ...mapGetters('jobOffers', ['getJobOfferAddress']),
    refreshRepresentatives() {
      this.employerRepresentative = null
      if (this.employer) {
        this.getRepresentatives(this.employer.id)
      }
    },
    changeEmployer() {
      if (this.employer) {
        this.setEmployer(this.employer)
        this.refreshRepresentatives()
        if (this.getJobOfferAddress() === null) {
          // Prefill the jobOffer's address if none is saved yet
          this.$emit("input", {
            address: {
              address1: this.employer.address.address1,
              address2: this.employer.address.address2,
              zipCode: this.employer.address.zipCode,
              city: this.employer.address.city
            }
          })
        }
      }
    },
    changeRepresentative() {
      this.setRepresentative(this.employerRepresentative)
    }
  }
}
</script>
