<template>
  <v-card class="pa-8">
    <v-row>
      <ComponentTitleWithDataLoader
        :title="this.$t('job_offers.description.title')"
      ></ComponentTitleWithDataLoader>
    </v-row>
    <v-container v-if="!dataIsLoading">
      <v-row>
        <v-col>
          <v-text-field
            v-model="name"
            :label="$t('job_offers.description.name')"
            :rules="[rules.required]"
            class="required"
            data-cy="job_offers.description.name"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>

          <v-text-field
            v-model="address1"
            :label="$t('global.address.address1')"
            :rules="[rules.required]"
            class="required"
            data-cy="address.address_1"
          />
          <v-text-field
            v-model="address2"
            :label="$t('global.address.address2')"
            data-cy="address.address_2"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="zipCode"
            :label="$t('global.address.zipCode')"
            :rules="[rules.required]"
            class="required"
            data-cy="address.zip_code"
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="city"
            :label="$t('global.address.city')"
            :rules="[rules.required]"
            class="required"
            data-cy="address.city"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-textarea
            v-model="description"
            :label="$t('job_offers.description.text')"
            :hint="$t('job_offers.description.text_hint')"
            persistent-hint
            data-cy="job_offers.description.text"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            id="minimumSalary"
            v-model="minimumSalary"
            :label="$t('job_offers.description.minimum_salary')"
            data-cy="job_offers.description.minimum_salary"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-radio-group
            v-model="contractType"
            :column="false"
            :value="contractType"
          >
            <v-radio
              v-for="(v, k) in contractTypes"
              :key="k"
              :label="v"
              :value="k"
              class="pr-6"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col>
          <v-text-field
            v-if="contractType === 0"
            v-model="contractDuration"
            :label="$t('job_offers.description.contract_duration')"
            :rules="[rules.required, rules.numberRule]"
            type="number"
            class="required"
            data-cy="contract_duration"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-checkbox
            v-model="criminalRecordExtractNeeded"
            :label="$t('job_offers.description.is_legal_record_required')"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import {mapGetters, mapState} from 'vuex'
import ComponentTitleWithDataLoader from '@/components/layout/ComponentTitleWithDataLoader.vue'

export default {
  components: { ComponentTitleWithDataLoader },
  props: {
    // Contains all the fields
    offerDescription: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      name: null,
      address1: null,
      address2: null,
      zipCode: null,
      city: null,
      description: null,
      endDate: null,
      minimumSalary: null,
      contractDuration: null,
      criminalRecordExtractNeeded: 0,
      contractType: 1,
      rules: {
        required: v => !!v,
        numberRule: val => {
          if (val <= 0) return 'Veuillez entrer une valeur positive'
          return true
        }
      }
    }
  },
  computed: {
    ...mapGetters('page', ['dataIsLoading']),
    ...mapState('jobOffers', ['jobOffer']),
    contractTypes() {
      return ['CDD', 'CDI']
    },
    updateData() {
      const {
        name,
        address1,
        address2,
        zipCode,
        city,
        description,
        endDate,
        minimumSalary,
        contractDuration,
        criminalRecordExtractNeeded,
        contractType
      } = this
      return {
        name,
        address1,
        address2,
        zipCode,
        city,
        description,
        endDate,
        minimumSalary,
        contractDuration,
        criminalRecordExtractNeeded,
        contractType
      }
    }
  },
  watch: {
    updateData: {
      handler() {
        this.$emit('input', {
          name: this.name,
          address: {
            address1: this.address1,
            address2: this.address2,
            zipCode: this.zipCode,
            city: this.city,
          },
          description: this.description,
          endDate: this.endDate,
          minimumSalary: this.minimumSalary,
          contractDuration: this.contractDuration,
          criminalRecordExtractNeeded: this.criminalRecordExtractNeeded,
          contractType: this.contractType
        })
      }
    },
    offerDescription: {
      handler() {
        this.name = this.offerDescription.name

        if (this.offerDescription.address !== null) {
          this.address1 = this.offerDescription.address.address1
          this.address2 = this.offerDescription.address.address2
          this.zipCode = this.offerDescription.address.zipCode
          this.city = this.offerDescription.address.city
        }

        this.description = this.offerDescription.description
        this.endDate = this.offerDescription.endDate
        this.minimumSalary = this.offerDescription.minimumSalary
        this.contractDuration = this.offerDescription.contractDuration
        this.criminalRecordExtractNeeded =
          this.offerDescription.criminalRecordExtractNeeded
        this.contractType = this.offerDescription.contractType
      }
    }
  },
  methods: {}
}
</script>
